import React, {useMemo} from 'react';
import {isMobile} from 'react-device-detect';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {ProductBackgroundImage} from 'components';
import styled from 'styled-components';
import {Product} from 'components/customer/Product/entity/Product';

const NON_SUPPLY = 7;

const BorderImage = styled.div<{$isMobile: boolean}>`
    width: ${({$isMobile}) => ($isMobile ? '100px' : '150px')};
    height: ${({$isMobile}) => ($isMobile ? '100px' : '150px')};
    outline-width: 1px;
    outline-style: solid;
    outline-color: rgb(var(--primary_colour));
    border-radius: 10px;
`;

export const ProductDetail = ({product}: {product: Product}) => {
    const isSmallDevice = isDeviceSmall();
    const [exteriorLabel, interiorLabel] = useMemo(() => {
        return [
            !isMobile ? 'Exterior Material:' : 'Ext:',
            !isMobile ? 'Carcase Material:' : 'Carc:',
        ];
    }, [isMobile]);

    const backgroundImage = useMemo(() => {
        return `url("/uploads/gocabinet_materials/${
            product &&
            product.extMaterial.image != '' &&
            !product.type_image.endsWith('.svg')
                ? product.extMaterial.image
                : 'default_exterior.jpg'
        }")`;
    }, [product]);

    const is3DImageLoading = Boolean(product.is_cabinet_3d_image_loading);

    return (
        <div className="product-detail">
            <BorderImage $isMobile={isMobile}>
                <ProductBackgroundImage
                    style={{borderRadius: '10px'}}
                    image={backgroundImage}
                    transform={product.ext_hor_grain}
                    src={`${product.type_image}?cache_bust=${Date.now()}`}
                    fallbackSrc={
                        !product.type_image.startsWith('uploads')
                            ? `/uploads/${product.type_image}`
                            : ''
                    }
                    alt={product?.type_name}
                    cachingInProgress={is3DImageLoading}
                />
            </BorderImage>
            <div style={isSmallDevice ? {} : {minWidth: '200px'}}>
                <h3>
                    <strong>
                        {!product.note ? product.type_name : product.note}
                    </strong>
                </h3>
                {!isMobile ? (
                    <p>
                        <i>{product.description}</i>
                    </p>
                ) : null}
                {product.extMaterial.type_id != NON_SUPPLY ? (
                    <>
                        <h3 style={{fontSize: '12px', marginBottom: '0px'}}>
                            {exteriorLabel}{' '}
                        </h3>
                        <p>
                            {product.extMaterial.name}{' '}
                            {product.extMaterial.is_custom_colour
                                ? product.ext_custom_colour
                                : '' + ' '}
                            <em>{product.extMaterial.finish}</em> -{' '}
                            {product.extMaterial.thickness}mm{' '}
                            {product.extMaterial.brand.name}{' '}
                            {product.extMaterial.substrate
                                ? product.extMaterial.substrate
                                : '' + ' '}
                        </p>
                    </>
                ) : null}
                {product.carcMaterial.type_id != NON_SUPPLY ? (
                    <>
                        <h3 style={{fontSize: '12px', marginBottom: '0px'}}>
                            {interiorLabel}{' '}
                        </h3>
                        <p>
                            {product.carcMaterial.name}{' '}
                            {product.carcMaterial.is_custom_colour
                                ? product.carc_custom_colour
                                : '' + ' '}
                            <em>{product.carcMaterial.finish}</em> -{' '}
                            {product.carcMaterial.thickness}mm{' '}
                            {product.carcMaterial.brand.name}{' '}
                            {product.carcMaterial.substrate
                                ? product.carcMaterial.substrate
                                : '' + ' '}
                        </p>
                    </>
                ) : null}
                {product.comment ? (
                    <p>
                        <strong>Variation: </strong>
                        <span style={{color: '#FF0000'}}>
                            {product.comment}{' '}
                        </span>
                    </p>
                ) : null}
            </div>
        </div>
    );
};
