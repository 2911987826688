import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {
    MenuItem,
    Menu as MenuEnum,
} from 'components/customer/AdvancedMaterials/entity/Menu';
import {useAppDispatch} from 'store/customer';
import {selectedMenuSet} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {SubMenu} from 'components/customer/AdvancedMaterials/Menu/SubMenu';
import {Icon} from 'shared/helpers';
import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
} from '@reduxjs/toolkit';

interface MenuInterface<T> {
    text: string;
    id: MenuEnum;
    selected: MenuEnum;
    submenu?: T[];
    action?: ActionCreatorWithPayload<string>;
    actionClearAll?: ActionCreatorWithoutPayload;
}

export const MainMenu = forwardRef(function MainMenu<T extends MenuItem>(
    {
        text,
        id,
        selected,
        submenu = [],
        action,
        actionClearAll,
    }: MenuInterface<T>,
    container: React.Ref<HTMLDivElement>
) {
    const self = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [maxHeight, setMaxHeight] = useState('auto');

    const selectMenu = useCallback(() => {
        dispatch(selectedMenuSet(id, true));
    }, [id]);

    const isActive = useMemo(() => selected == id, [id, selected]);

    const handleClearAllDoorTypes = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            dispatch(actionClearAll());
        },
        [submenu]
    );

    useEffect(() => {
        if (isActive && self.current && 'current' in container) {
            const containerHeight = container.current.offsetHeight;

            let totalHeight = 0;
            for (let i = 0; i < self.current.children.length; i++) {
                const element = self.current.children[Number(i)] as HTMLElement;
                totalHeight += element.offsetHeight;
            }

            totalHeight += (self.current.children.length - 1) * 5;

            if (totalHeight < containerHeight - 33 * 3) {
                setMaxHeight(`${totalHeight}px`);
            } else {
                setMaxHeight('fit-content');
            }
        } else {
            setMaxHeight('fit-content');
        }
    }, [isActive, submenu]);

    return (
        <>
            <MenuElement $active={isActive} onClick={selectMenu}>
                <div>{text}</div>
                {!isActive ? (
                    <MenuIcon iconName="ChevronDownWhite.svg" />
                ) : null}
            </MenuElement>
            {isActive ? (
                <SubmenuContainer ref={self} style={{maxHeight}}>
                    {text === 'Door Type' &&
                    submenu.some((menu) => menu.selected) &&
                    submenu.every((menu) => !menu?.isViewAll) ? (
                        <ClearAllContainer>
                            <Button onClick={handleClearAllDoorTypes}>
                                Clear all
                            </Button>
                        </ClearAllContainer>
                    ) : null}

                    {submenu.length > 0
                        ? submenu.map((menu) => {
                              return (
                                  <SubMenu
                                      item={menu}
                                      key={menu.id}
                                      action={action}
                                  />
                              );
                          })
                        : null}
                </SubmenuContainer>
            ) : null}
        </>
    );
});

const MenuElement = styled.div<{$active: boolean}>`
    background: ${({$active}) =>
        $active
            ? 'rgb(var(--secondary_colour))'
            : 'rgb(var(--primary_colour))'};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 6px 10px;
    color: white;
    font-weight: 900;
    font-size: 1em;
    text-align: center;
    cursor: ${({$active}) => ($active ? 'initial' : 'pointer')};
    display: flex;

    > div {
        flex: 1;
    }

    :last-of-type {
        margin-bottom: 0;
    }
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
const MenuIcon = styled((props) => <Icon {...props} />)`
    width: 13px !important;
    margin: 0 6px;
`;

const SubmenuContainer = styled.div`
    flex: 1;
    padding: 0 2vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    :hover::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar {
        width: 2px;
    }

    :hover::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.5);
    }
`;

const ClearAllContainer = styled.div`
    text-align: right;
`;

const Button = styled.button`
    border: 0px;
    background: rgb(var(--secondary_colour));
    color: white;
    border-radius: 6px;
    font-weight: 500;
    padding: 4px 5px;
    font-size: 12px;

    :hover {
        background: rgb(var(--primary_colour));
    }

    :disabled {
        background: rgb(var(--secondary_colour));
        opacity: 0.5;
    }
`;
