import React, {useCallback} from 'react';
import {MenuItem} from 'components/customer/AdvancedMaterials/entity/Menu';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';
import {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import {useAppDispatch} from 'store/customer';
import {currentPageSet} from 'components/customer/AdvancedMaterials/store/materialSlice';
import styled from 'styled-components';

export const SubMenu = ({
    item,
    action,
}: {
    item: MenuItem;
    action: ActionCreatorWithPayload<string>;
}) => {
    const dispatch = useAppDispatch();

    const handleChange = useCallback(() => {
        dispatch(currentPageSet(1));
        dispatch(action(item.id));
    }, [action, item]);

    return (
        <RadioContainer $hidden={item.hidden}>
            <Radio
                $checked={item?.isViewAll || item.selected}
                $disabled={item.disabled}
                $rightCheckbox={true}>
                {item.name}
                <input type="checkbox" onChange={handleChange} />
            </Radio>
        </RadioContainer>
    );
};

const RadioContainer = styled.div<{$hidden: boolean}>`
    display: ${({$hidden}) => ($hidden ? 'none' : 'initial')};
`;

const Radio = styled(MiniRadio)`
    padding-left: 0;
    padding-right: 16px;
    min-height: 22px;
    margin-bottom: 0;
`;
