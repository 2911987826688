import {useGetTypesQuery} from 'components/customer/Materials/store/materialApi';

export const useMaterialTypes = (
    cabinetType: number,
    defaultSelected?: boolean
) => {
    const {
        data: materialTypes,
        isLoading,
        isError,
    } = useGetTypesQuery({cabinetType, defaultSelected});

    return {
        isLoading,
        isError,
        materialTypes,
    };
};
